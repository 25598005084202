<template>
  <ion-page>
    <ion-content>
      <ion-grid class="start-hero ion-grid-width-xl">
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="10" size-lg="12" size-md="12" class="ion-no-margin ion-no-padding">
            <LgtronImage class="image" :src="'/assets/images/start-hero.jpg'"></LgtronImage>
            <div class="start-text">
              <p>{{ $t('start.imageTitle') }}</p>
              <p>{{ $t('start.imageTitle2') }}</p></div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-title align="center">{{ $t('start.categoryTitle') }}</ion-title>

      <ion-grid class="product-preview">
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="10" size-lg="12" size-md="12" pull="0">
            <ion-row class="ion-justify-content-between">
              <ion-col size-md="4" size-sm="6" size-xs="12">
                <ion-card router-link="/products#fruits">
                  <LgtronImage class="image" :src="'/assets/images/strawberries.jpg'"></LgtronImage>
                  <ion-card-header>
                    <ion-card-title>{{ $t('start.fruitTitle') }}</ion-card-title>
                  </ion-card-header>
                </ion-card>
              </ion-col>
              <ion-col size-md="4" size-sm="6" size-xs="12">
                <ion-card router-link="/products#vegetables">
                  <LgtronImage class="image" :src="'/assets/images/corn.jpg'"></LgtronImage>
                  <ion-card-header>
                    <ion-card-title>{{ $t('start.vegetableTitle') }}</ion-card-title>
                  </ion-card-header>
                </ion-card>
              </ion-col>
              <ion-col size-md="4" size-sm="6" size-xs="12">
                <ion-card router-link="/products#spices">
                  <LgtronImage class="image" :src="'/assets/images/rosemary.jpg'"></LgtronImage>
                  <ion-card-header>
                    <ion-card-title>{{ $t('start.spicesTitle') }}</ion-card-title>
                  </ion-card-header>
                </ion-card>
              </ion-col>
              <ion-col size-md="4" size-sm="6" size-xs="12">
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
      <LgtronFooter></LgtronFooter>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonTitle,
  IonContent,
  IonImg,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/vue';
import {LgtronImage, LgtronFooter} from "@/components";

export default {
  name: 'StartTab',
  components: {
    IonTitle,
    IonContent,
    IonPage,
    LgtronImage,
    IonCol,
    IonGrid,
    IonRow,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    LgtronFooter
  },
}
</script>

<style scoped lang="scss">

ion-card {
  margin: 0;

  .image {
    height: 15rem;
    width: 100%;
    object-fit: cover;
  }
}

.start-hero {
  p {
    margin: 0;
  }

  .image {
    height: 40rem;
    width: 100%;
    object-fit: cover;
  }

  .start-text {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    justify-content: center;
    height: 100%;
    padding: 0 20%;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 75rem) {

  ion-card {
    .image {
      height: auto;
    }
  }
  .start-hero {
    .image {
      height: 30rem;
    }

    .start-text {
      font-size: 1.2rem;
      padding: 0 15%;
    }
  }
}

@media screen and (max-width: 40rem) {

  .start-hero {
    .image {
      height: 20rem;
      object-fit: cover;
    }

    .start-text {
      background: rgba(255, 255, 255, .8);
      height: 50%;
      font-size: 1rem;
      padding: 1rem;
      top: 25%;
    }
  }
}

@media screen and (max-width: 30rem) {
  ion-col {
    min-width: 100%;
  }
}

</style>