
import {
  IonPage,
  IonTitle,
  IonContent,
  IonImg,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/vue';
import {LgtronImage, LgtronFooter} from "@/components";

export default {
  name: 'StartTab',
  components: {
    IonTitle,
    IonContent,
    IonPage,
    LgtronImage,
    IonCol,
    IonGrid,
    IonRow,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    LgtronFooter
  },
}
